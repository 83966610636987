.Dropzone {
    border-width: 1px;
    border-style: dashed;
    border-radius: 5px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.8rem;
}

.DropzoneLoading {
    border-color: gray;
    color: gray;
}

.DropzoneDone {
    border-color: #65B32E;
    color: #65B32E;
}
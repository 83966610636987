.Slider {
  display: flex;
  height: auto;
  max-height: 220px;
  overflow-x: auto;
  width: 100%;
}

@media screen and (min-width: 576px) {
  .Slider {
    display: block;
    max-height: 100%;
    width: auto;
  }
}

